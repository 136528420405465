<template>
  <div>
    <form class="needs-validation" @submit.prevent="submitCreateConfig">
      <div class="form-group">
        <label for="config_code">config_code</label>
        <input
            id="config_code"
            v-model="newConfig.config_code"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newConfig.config_code.$error }"
        />
        <div v-if="submitted && $v.newConfig.config_code.$error" class="invalid-feedback">
          <span v-if="!$v.newConfig.config_code.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newConfig.config_code.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.newConfig.config_code.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 65
                      </span>
        </div>
      </div>

      <div class="form-group">
        <label for="config_value">config_value</label>
        <textarea
            id="config_value"
            v-model="newConfig.config_value"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newConfig.config_value.$error }"
        ></textarea>
        <div v-if="submitted && $v.newConfig.config_value.$error" class="invalid-feedback">
          <span v-if="!$v.newConfig.config_value.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newConfig.config_value.minLength">
                        {{$t('validatePhrases.minLength')}}: 1
                      </span>
          <span v-if="!$v.newConfig.config_value.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 500
                      </span>
        </div>
      </div>

      <div class="form-group">
        <label for="config_description">config_description</label>
        <textarea
            id="config_description"
            v-model="newConfig.config_description"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newConfig.config_description.$error }"
        ></textarea>
        <div v-if="submitted && $v.newConfig.config_description.$error" class="invalid-feedback">
          <span v-if="!$v.newConfig.config_description.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newConfig.config_description.minLength">
                        {{$t('validatePhrases.minLength')}}: 1
                      </span>
          <span v-if="!$v.newConfig.config_description.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 250
                      </span>
        </div>
      </div>


      <div class="form-group">
        <label for="config_type">config_type</label>
        <select v-model="newConfig.config_type" class="form-control form-control-sm">
          <option value="string">string</option>
          <option value="json">json</option>
        </select>
      </div>

    </form>
  </div>
</template>

<script>

import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  name: "EditConfig",
  components: {  },
  data(){
    return {
      newConfig: {
        id: 0,
        config_code: '',
        config_value: '',
        config_description: '',
        config_type: ''
      },
      submitted: false,
    }
  },
  props: {
    default: Object,
    submitConf: Number
  },
  watch: {
    default: function(val){
      this.newConfig.id = val.id;
      this.newConfig.config_code = val.config_code;
      this.newConfig.config_value = val.config_value;
      this.newConfig.config_description = val.config_description;
      this.newConfig.config_type = val.config_type;
    },
    submitConf: function(val){
      if(val > 0){
        this.submitCreateConfig();
      }
    }
  },
  computed: {

  },
  validations: {
    newConfig: {
      config_code: { required, minLength: minLength(3), maxLength: maxLength(65) },
      config_value: { minLength: minLength(0), maxLength: maxLength(500) },
      config_description: { required, minLength: minLength(1), maxLength: maxLength(250) }
    }
  },
  methods: {
    submitCreateConfig(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newConfig.$touch();
      if(!this.$v.newConfig.$invalid){
        this.$emit('submitCreate', this.newConfig);
      }
    },
  },
  created() {

  }

};
</script>

<style scoped lang="css">

</style>
