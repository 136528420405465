<template>
  <div class="printer-price--container">
    <div class="row">
      <div class="col-lg-12 text-center">A4</div>
    </div>


<!--      А4-->
      <div v-if="type === 'common'" class="row mt-2">
        <div class="col-lg-4">
          <label for="printerPrice_GRAYSCALE_A4">
            {{$t('popularWords.bw')}} A4 {{$t('components.printerPrice.oneSide')}} (1+0)
          </label>
          <input
              id="printerPrice_GRAYSCALE_A4"
              type="text"
              class="form-control form-control-sm"
              v-model="price.GRAYSCALE_A4"
              @input="emitComponent"
          />
        </div>
        <div class="col-lg-4">
          <label for="printerPrice_COLOR_A4">
            {{$t('popularWords.color')}} A4 {{$t('components.printerPrice.oneSide')}} (4+0)
          </label>
          <input
              id="printerPrice_COLOR_A4"
              type="text"
              class="form-control form-control-sm"
              v-model="price.COLOR_A4"
              @input="emitComponent"
          />
        </div>
        <div class="col-lg-4">
          <label for="printerPrice_GRAYSCALE_DUPLEX_A4">
            {{$t('popularWords.bw')}} A4 {{$t('components.printerPrice.twoSide')}} (1+1)
          </label>
          <input
              id="printerPrice_GRAYSCALE_DUPLEX_A4"
              type="text"
              class="form-control form-control-sm"
              v-model="price.GRAYSCALE_DUPLEX_A4"
              @input="emitComponent"
          />
        </div>
        <div class="col-lg-4">
          <label for="printerPrice_COLOR_DUPLEX_A4">
            {{$t('popularWords.color')}} A4 {{$t('components.printerPrice.twoSide')}} (4+4)
          </label>
          <input
              id="printerPrice_COLOR_DUPLEX_A4"
              type="text"
              class="form-control form-control-sm"
              v-model="price.COLOR_DUPLEX_A4"
              @input="emitComponent"
          />
        </div>
      </div>

<!--      A3-->
    <div v-if="isProduction" class="row mt-2">
      <div class="col-lg-12 text-center">A3</div>
    </div>
    <div v-if="isProduction" class="row">
      <div class="col-lg-4">
        <label for="printerPrice_GRAYSCALE_A3">
          {{$t('popularWords.bw')}} A3 {{$t('components.printerPrice.oneSide')}} (1+0)
        </label>
        <input
            id="printerPrice_GRAYSCALE_A3"
            type="text"
            class="form-control form-control-sm"
            v-model="price.GRAYSCALE_A3"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_COLOR_A3">
          {{$t('popularWords.color')}} A3 {{$t('components.printerPrice.oneSide')}} (4+0)
        </label>
        <input
            id="printerPrice_COLOR_A3"
            type="text"
            class="form-control form-control-sm"
            v-model="price.COLOR_A3"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_GRAYSCALE_DUPLEX_A3">
          {{$t('popularWords.bw')}} A3 {{$t('components.printerPrice.twoSide')}} (1+1)
        </label>
        <input
            id="printerPrice_GRAYSCALE_DUPLEX_A3"
            type="text"
            class="form-control form-control-sm"
            v-model="price.GRAYSCALE_DUPLEX_A3"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_COLOR_DUPLEX_A3">
          {{$t('popularWords.color')}} A3 {{$t('components.printerPrice.twoSide')}} (4+4)
        </label>
        <input
            id="printerPrice_COLOR_DUPLEX_A3"
            type="text"
            class="form-control form-control-sm"
            v-model="price.COLOR_DUPLEX_A3"
            @input="emitComponent"
        />
      </div>
    </div>

<!--      A2-->
    <div v-if="isProduction" class="row mt-2">
      <div class="col-lg-12 text-center">A2</div>
    </div>
    <div v-if="isProduction" class="row">
      <div class="col-lg-4">
        <label for="printerPrice_GRAYSCALE_A2">
          {{$t('popularWords.bw')}} A2 {{$t('components.printerPrice.oneSide')}} (1+0)
        </label>
        <input
            id="printerPrice_GRAYSCALE_A2"
            type="text"
            class="form-control form-control-sm"
            v-model="price.GRAYSCALE_A2"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_COLOR_A2">
          {{$t('popularWords.color')}} A2 {{$t('components.printerPrice.oneSide')}} (4+0)
        </label>
        <input
            id="printerPrice_COLOR_A2"
            type="text"
            class="form-control form-control-sm"
            v-model="price.COLOR_A2"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_GRAYSCALE_DUPLEX_A2">
          {{$t('popularWords.bw')}} A2 {{$t('components.printerPrice.twoSide')}} (1+1)
        </label>
        <input
            id="printerPrice_GRAYSCALE_DUPLEX_A2"
            type="text"
            class="form-control form-control-sm"
            v-model="price.GRAYSCALE_DUPLEX_A2"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_COLOR_DUPLEX_A2">
          {{$t('popularWords.color')}} A2 {{$t('components.printerPrice.twoSide')}} (4+4)
        </label>
        <input
            id="printerPrice_COLOR_DUPLEX_A2"
            type="text"
            class="form-control form-control-sm"
            v-model="price.COLOR_DUPLEX_A2"
            @input="emitComponent"
        />
      </div>
    </div>

    <!--      A1-->
    <div v-if="isProduction" class="row mt-2">
      <div class="col-lg-12 text-center">A1</div>
    </div>
    <div v-if="isProduction" class="row">
      <div class="col-lg-4">
        <label for="printerPrice_GRAYSCALE_A1">
          {{$t('popularWords.bw')}} A1 {{$t('components.printerPrice.oneSide')}} (1+0)
        </label>
        <input
            id="printerPrice_GRAYSCALE_A1"
            type="text"
            class="form-control form-control-sm"
            v-model="price.GRAYSCALE_A1"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_COLOR_A1">
          {{$t('popularWords.color')}} A1 {{$t('components.printerPrice.oneSide')}} (4+0)
        </label>
        <input
            id="printerPrice_COLOR_A1"
            type="text"
            class="form-control form-control-sm"
            v-model="price.COLOR_A1"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_GRAYSCALE_DUPLEX_A1">
          {{$t('popularWords.bw')}} A1 {{$t('components.printerPrice.twoSide')}} (1+1)
        </label>
        <input
            id="printerPrice_GRAYSCALE_DUPLEX_A1"
            type="text"
            class="form-control form-control-sm"
            v-model="price.GRAYSCALE_DUPLEX_A1"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_COLOR_DUPLEX_A1">
          {{$t('popularWords.color')}} A1 {{$t('components.printerPrice.twoSide')}} (4+4)
        </label>
        <input
            id="printerPrice_COLOR_DUPLEX_A1"
            type="text"
            class="form-control form-control-sm"
            v-model="price.COLOR_DUPLEX_A1"
            @input="emitComponent"
        />
      </div>
    </div>

    <!--      A0-->
    <div v-if="isProduction" class="row mt-2">
      <div class="col-lg-12 text-center">A0</div>
    </div>
    <div v-if="isProduction" class="row">
      <div class="col-lg-4">
        <label for="printerPrice_GRAYSCALE_A0">
          {{$t('popularWords.bw')}} A0 {{$t('components.printerPrice.oneSide')}} (1+0)
        </label>
        <input
            id="printerPrice_GRAYSCALE_A0"
            type="text"
            class="form-control form-control-sm"
            v-model="price.GRAYSCALE_A0"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_COLOR_A0">
          {{$t('popularWords.color')}} A0 {{$t('components.printerPrice.oneSide')}} (4+0)
        </label>
        <input
            id="printerPrice_COLOR_A0"
            type="text"
            class="form-control form-control-sm"
            v-model="price.COLOR_A0"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_GRAYSCALE_DUPLEX_A0">
          {{$t('popularWords.bw')}} A0 {{$t('components.printerPrice.twoSide')}} (1+1)
        </label>
        <input
            id="printerPrice_GRAYSCALE_DUPLEX_A0"
            type="text"
            class="form-control form-control-sm"
            v-model="price.GRAYSCALE_DUPLEX_A0"
            @input="emitComponent"
        />
      </div>
      <div v-if="isProduction" class="col-lg-4">
        <label for="printerPrice_COLOR_DUPLEX_A0">
          {{$t('popularWords.color')}} A0 {{$t('components.printerPrice.twoSide')}} (4+4)
        </label>
        <input
            id="printerPrice_COLOR_DUPLEX_A0"
            type="text"
            class="form-control form-control-sm"
            v-model="price.COLOR_DUPLEX_A0"
            @input="emitComponent"
        />
      </div>
    </div>



    <!-- Production photo -->
    <div v-if="isProduction" class="row mt-2">
      <div class="col-lg-12 text-center">Фото</div>
        <div class="col-lg-4">
          <label for="printerPrice_PHOTO_10x15">
            10x15
          </label>
          <input
              id="printerPrice_PHOTO_10x15"
              type="text"
              class="form-control form-control-sm"
              v-model="price.PHOTO_10x15"
              @input="emitComponent"
          />
        </div>
        <div class="col-lg-4">
          <label for="printerPrice_PHOTO_15x21">
            15x21
          </label>
          <input
              id="printerPrice_PHOTO_15x21"
              type="text"
              class="form-control form-control-sm"
              v-model="price.PHOTO_15x21"
              @input="emitComponent"
          />
        </div>
        <div class="col-lg-4">
          <label for="printerPrice_PHOTO_15x15">
            15x15
          </label>
          <input
              id="printerPrice_PHOTO_15x15"
              type="text"
              class="form-control form-control-sm"
              v-model="price.PHOTO_15x15"
              @input="emitComponent"
          />
        </div>
        <div class="col-lg-4">
          <label for="printerPrice_PHOTO_15x18">
            15x18
          </label>
          <input
              id="printerPrice_PHOTO_15x18"
              type="text"
              class="form-control form-control-sm"
              v-model="price.PHOTO_15x18"
              @input="emitComponent"
          />
        </div>
        <div class="col-lg-4">
          <label for="printerPrice_PHOTO_21x30">
            21x30
          </label>
          <input
              id="printerPrice_PHOTO_21x30"
              type="text"
              class="form-control form-control-sm"
              v-model="price.PHOTO_21x30"
              @input="emitComponent"
          />
        </div>
        <div class="col-lg-4">
          <label for="printerPrice_PHOTO_30x40">
            30x40
          </label>
          <input
              id="printerPrice_PHOTO_30x40"
              type="text"
              class="form-control form-control-sm"
              v-model="price.PHOTO_30x40"
              @input="emitComponent"
          />
        </div>
        <div class="col-lg-4">
          <label for="printerPrice_PHOTO_30x60">
            30x60
          </label>
          <input
              id="printerPrice_PHOTO_30x60"
              type="text"
              class="form-control form-control-sm"
              v-model="price.PHOTO_30x60"
              @input="emitComponent"
          />
        </div>
    </div>

    <!-- Self-service photo -->
    <div v-if="type === 'photo'" class="row mt-2">
      <div class="col-lg-12 text-center">Фото</div>
      <div class="col-lg-4">
        <label for="printerPrice_SELF_SERVICE_PHOTO_10x15">
          10x15
        </label>
        <input
            id="printerPrice_SELF_SERVICE_PHOTO_10x15"
            type="text"
            class="form-control form-control-sm"
            v-model="price.SELF_SERVICE_PHOTO_10x15"
            @input="emitComponent"
        />
      </div>
      <div class="col-lg-4">
        <label for="printerPrice_SELF_SERVICE_PHOTO_15x20">
          15x21
        </label>
        <input
            id="printerPrice_SELF_SERVICE_PHOTO_15x20"
            type="text"
            class="form-control form-control-sm"
            v-model="price.SELF_SERVICE_PHOTO_15x20"
            @input="emitComponent"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrinterPrice",
  data() {
    return {
      price: {
        COLOR_A0: '',
        COLOR_A1: '',
        COLOR_A2: '',
        COLOR_A3: '',
        COLOR_A4: '',

        COLOR_DUPLEX_A0: '',
        COLOR_DUPLEX_A1: '',
        COLOR_DUPLEX_A2: '',
        COLOR_DUPLEX_A3: '',
        COLOR_DUPLEX_A4: '',

        GRAYSCALE_A0: '',
        GRAYSCALE_A1: '',
        GRAYSCALE_A2: '',
        GRAYSCALE_A3: '',
        GRAYSCALE_A4: '',

        GRAYSCALE_DUPLEX_A0: '',
        GRAYSCALE_DUPLEX_A1: '',
        GRAYSCALE_DUPLEX_A2: '',
        GRAYSCALE_DUPLEX_A3: '',
        GRAYSCALE_DUPLEX_A4: '',

        PHOTO_10x15: '',
        PHOTO_15x15: '',
        PHOTO_15x18: '',
        PHOTO_15x21: '',
        PHOTO_21x30: '',
        PHOTO_30x40: '',
        PHOTO_30x60: '',

        SELF_SERVICE_PHOTO_10x15: '',
        SELF_SERVICE_PHOTO_15x20: '',
      }
    };
  },
  props: {
    default: Object,
    isProduction: Boolean,
    type: String,
  },
  computed: {},
  watch: {
    price: function(){
      this.emitComponent();
    },
    default: function(val){
      this.price = val;
    },
  },
  methods: {
    emitComponent(){
      this.$emit('emitComponent', this.price);
    }
  },
  created() {}
};
</script>

<style scoped lang="scss">

</style>
