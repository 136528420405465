<template>
  <div>
    <div class="row cursor-pointer">
      <div @click="toggleShowMore" class="col-lg-9">
        <div class="row">
          <div class="col-lg-2 font-15">
            <b>Заказ №{{ orderData.id }}</b>
          </div>
          <div class="col-lg-10 font-15">
            <div v-if="orderData.delivery != 0 || orderData.delivery_address !== null">
              <b>Заказ с доставкой по адресу: </b> {{ orderData.delivery_address }}
            </div>
            <div v-if="orderData.point_name !== null">
              <b>Заказ в пункт выдачи: </b> {{ orderData.point_name }} ({{ orderData.point_address }})
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-2 font-13">
            {{ dateTimeToShort(orderData.created_at) }}
          </div>
          <div class="col-lg-10 font-15">
            <b>Тип заказа: </b> {{ orderData.service_name ? orderData.service_name : 'Печать документов' }}
            <span v-if="orderData.service_id == 3">
              ({{ orderData.type_rus }})
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="row">
          <div class="col-lg-12 text-right">
            <div class="btn-group">
              <b-dropdown
                size="sm"
                :variant="buttonStatusColor(orderData.status_key)"

              >
                <template v-slot:button-content>
                  {{getStatusName(orderData.status_key)}}
                  <i class="mdi mdi-chevron-down"></i>
                </template>
                  <b-dropdown-item
                    href="javascript: void(0);"
                    v-for="status in statuses()"
                    :key="status"
                    @click="setStatus(orderData.id, status, +orderData.delivery)"
                  >{{ getStatusName(status) }}</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="row mt-2 text-right font-15">
          <div class="col-lg-12" title="Стоимость заказа (с вычетом бонусов) + доставка">
            Итоговая сумма: {{ computedEndSum }} р.
          </div>
          <div class="col-lg-12 small" style="opacity: 0.5" v-if="orderData.price_point > 0">
            ({{ Math.floor(orderData.price_point) }} р. оплачено баллами)
          </div>
        </div>
      </div>
    </div>


    <div v-show="showMore" class="row mt-4">
      <div class="col-lg-12">
        <table class="table table-hover" style="cursor: pointer;">
          <thead>
          <tr v-if="orderData.service_id === null">
            <th>Цвет</th>
            <th>Формат</th>
            <th>Двусторон.</th>
            <th>Копий</th>
            <th>Клиент</th>
            <th>Телефон</th>
            <th></th>
          </tr>
          <tr v-else>
            <th v-for="(item, key) in dynamicFields[orderData.service_id]" :key="key">
              {{ Object.values(item)[0] }}
            </th>
            <th>Клиент</th>
            <th>Телефон</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="orderData.service_id === null">
            <td>
              <i v-if="+orderData.color === 1" class="ri-checkbox-circle-fill text-success font-size-18"></i>
              <i v-if="+orderData.color === 0" class="ri-close-circle-fill text-danger font-size-18"></i>
            </td>
            <td>{{ orderData.format }}</td>
            <td>
              <i v-if="+orderData.duplex === 1" class="ri-checkbox-circle-fill text-success font-size-18"></i>
              <i v-if="+orderData.duplex === 0" class="ri-close-circle-fill text-danger font-size-18"></i>
            </td>
            <td>{{ orderData.copies }}</td>
            <td>{{ orderData.user_id }}</td>
            <td>{{ orderData.user_number }}</td>
            <td style="text-align: right;">
              <div v-if="userPermissions.includes('show-orders-file')" @click="toggleShowFiles" class="btn btn-sm btn-light mr-2">Скачать файлы</div>
              <div @click="showLogOrderPopup(orderData.id)" class="btn btn-sm btn-light mr-2">Открыть логи</div>
              <div v-if="userPermissions.includes('refund')" @click="refundOrder(orderData.id)" class="btn btn-sm btn-light mr-2">Возврат средств</div>
              <div @click="printOrder(orderData.id)" class="btn btn-sm btn-light">Запустить печать</div>
            </td>
          </tr>
          <tr v-else>
            <td v-for="(item, key) in dynamicFields[orderData.service_id]" :key="key">
              {{ orderData[Object.keys(item)]  }}
            </td>
            <td>{{ orderData.user_id }}</td>
            <td>{{ orderData.user_number }}</td>
            <td>
              <div v-if="userPermissions.includes('show-orders-file')" @click="toggleShowFiles" class="btn btn-sm btn-light mr-2">Скачать</div>
              <div v-if="userPermissions.includes('refund')" @click="refundOrder(orderData.id)" class="btn btn-sm btn-light">Возврат средств</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-show="showFiles && showMore" class="row mt-3">
      <div class="col-lg-12">
        <div class="font-15 font-weight-bold">Список файлов: </div>
        <div style="display: inline-block">
          <table class="mt-2 mb-2 table table-bordered table-sm">
            <thead>
            <tr>
              <th>#</th>
              <th>Файл</th>
              <th>Название</th>
              <th>Копий</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in orderData.files" :key="index">
              <td>
                {{index+1}}
              </td>
              <td>
                <a :href="item.path" target="_blank">
                  {{ item.stored_name }}
                </a>
              </td>
              <td>
                <span :title="item.original_name">{{ (item.original_name ? item.original_name : '&mdash;').slice(0, 50) }}</span>
              </td>
              <td>
                {{ item.copies }} шт.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div @click="getArchiveLink(orderData.id)" class="btn btn-sm btn-outline-info">Сформировать архив</div>
          <div
              v-if="orderData.service_id == 3"
              @click="getDocumentPhotoGrid(orderData.id)"
              class="btn btn-sm btn-outline-info ml-1"
          >
            Сформировать сетку
          </div>
        </div>
      </div>
    </div>

    <popup
      :closeButton="archiveFilesPopup.closeButton"
      :show="archiveFilesPopup.show"
      :size="'lg'"
      @closePopup="closeArchiveFilesPopup"
    >
      <div slot="header">Выгрузить файлы заказа №{{ achiveOrderId }}</div>
      <div slot="body">
        <div class="alert alert-sm alert-success">
          <div>Архив сформирован. Скачайте его по ссылке:</div>
          <div>
            <a :href="archiveLink" target="_blank">{{ archiveLink.slice(0, 40) }}...</a>
          </div>
        </div>
      </div>
    </popup>


    <popup
      :closeButton="documentPhotoGridPopup.closeButton"
      :show="documentPhotoGridPopup.show"
      :size="'lg'"
      @closePopup="closeDocumentPhotoGridPopup"
    >
      <div slot="header">Выгрузить PDF заказа №{{ documentPhotoGridOrderId }}</div>
      <div slot="body">
        <div class="alert alert-sm alert-success">
          <div>PDF сформирован. Скачайте его по ссылке:</div>
          <div>
            <a :href="documentPhotoGridLink" target="_blank">{{ documentPhotoGridLink.slice(0, 40) }}...</a>
          </div>
        </div>
      </div>
    </popup>


    <popup
      :closeButton="pinPopup.closeButton"
      :actionButton="pinPopup.actionButton"
      :actionClass="pinPopup.actionClass"
      :show="pinPopup.show"
      @closePopup="closePinPopup"
      @actionPopup="pinPopupSubmit"
    >
      <div slot="header">{{ $t('views.orders.confirm_order') }}</div>
      <div slot="body">
        <div class="text-center">
          {{ $t('views.orders.confirm_order_text') }}
        </div>
        <div class="mt-3">
          <div class="form-group">
            <input
                id="pin"
                v-model="pin"
                type="text"
                class="form-control form-control-sm"
            />
          </div>
        </div>
      </div>
    </popup>

  </div>
</template>

<script>



import axios from "axios";
//import SimpleSelectCheckbox from "@/components/SimpleSelectCheckbox";
import Formatter from "../../libraries/Formatter";
import { mapState } from "vuex";
import Popup from '../../Popup.vue';
import {getStatusName, ORDER_STATUSES, showConfirmDialog} from "@/helpers/utils";

const formatter = new Formatter();

export default {
  name: "OrderService",
  components: {Popup},
  data() {
    return {
      archiveFilesPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      achiveOrderId: '',
      archiveLink: '-',
      showMore: false,
      showFiles: false,

      documentPhotoGridPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      documentPhotoGridOrderId: '',
      documentPhotoGridLink: '',

      setStatusForce: false,
      pinPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      setStatusKey: 0,
      setStatusOrderId: 0,
      pin: '',

      dynamicFields: {
        1: [
          {paper: "Бумага"},
          {format: "Формат"},
          {borders: "С полями"},
        ],
        2: [
          {color: "Цвет"},
          {format: "Формат"},
          {duplex: "Двусторон."},
          {copies: "Копий"},
        ]
      }
    }
  },
  props: {
    orderData: Object
  },
  watch: {

  },
  computed: {
    ...mapState({
      userPermissions: state => state.authorization.userPerm,
    }),
    computedEndSum(){
      let result = +this.orderData.price;
      if(+this.orderData.use_point === 1){
        result -= +this.orderData.price_point;
      }
      if(+this.orderData.delivery === 1 || this.orderData.delivery_address !== null){
        result += +this.orderData.delivery_price;
      }
      return result;
    }
  },
  validations: {

  },
  methods: {
    getStatusName,
    statuses() {
      return ORDER_STATUSES;
    },
    closeDocumentPhotoGridPopup(){
      this.documentPhotoGridPopup.show = false;
    },
    getDocumentPhotoGrid(orderId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);

      axios
        .post(`/v1/grid/document-photo`, formData)
        .then(resp => {
          this.documentPhotoGridLink = resp.data.download_link;
          this.documentPhotoGridOrderId = orderId;
          this.documentPhotoGridPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    getArchiveLink(orderId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);

      axios
        .post(`/v1/orders/get-service-archive`, formData)
        .then(resp => {
          this.archiveLink = resp.data.archive_path;
          this.achiveOrderId = orderId;
          this.archiveFilesPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    closeArchiveFilesPopup(){
      this.archiveFilesPopup.show = false;
    },
    refundOrder(orderId){
      showConfirmDialog(`Вы действительно хотите сделать возврат средств по заказу №${orderId}?`, { ok: 'Вернуть средства' })
          .then(() => {
            this.$store.dispatch("startPreloader");
            let formData = new FormData();
            formData.append("order_id", orderId);
            formData.append("type", 'service');

            axios
                .post(`/v1/orders/refund`, formData)
                .then(resp => {
                  if(resp.data.result == 'ok'){
                    this.$store.dispatch("addNotification", {
                      text: resp.data.message,
                      time: 3,
                      color: "success"
                    });
                    this.$emit('updateList');
                  }else{
                    this.$store.dispatch("addNotification", {
                      text: resp.data.message,
                      time: 8,
                      color: "danger"
                    });
                  }
                  this.$store.dispatch("stopPreloader");
                })
                .catch(err => {
                  this.downloadBlock = false;
                  this.$store.dispatch("addNotification", {
                    text: err.response.data.message,
                    time: 6,
                    color: "danger"
                  });
                  this.$store.dispatch("stopPreloader");
                });
          });
    },
    closePinPopup(){
      this.pinPopup.show = false;
      this.pin = '';
    },
    showPinPopup(){
      this.pinPopup.show = true;
    },
    pinPopupSubmit(){
      this.setStatusForce = true;
      this.setStatus(
        this.setStatusOrderId,
        this.setStatusKey,
        0,
        'done'
      );
    },
    setStatus(orderId, statusKey, isDelivery){
      if(
        statusKey === 'done' &&
        isDelivery === 0 &&
        !this.setStatusForce &&
        !this.userPermissions.includes('completed-orders-force')
      ){
        this.setStatusOrderId = orderId;
        this.setStatusKey = statusKey;
        this.showPinPopup();
        return false;
      }
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("status_key", statusKey);
      formData.append("type", 'service');
      formData.append("pin", this.pin);

      axios
        .post(`/v1/orders/set-status`, formData)
        .then(() => {
          this.$emit('updateList');
          this.setStatusForce = false;
          this.closePinPopup();
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    dateTimeToShort(dt){
      return formatter.phpDateTimeToShortString(dt);
    },
    toggleShowMore(){
      this.showMore = !this.showMore;
    },
    toggleShowFiles(){
      this.showFiles = !this.showFiles;
    },
    buttonStatusColor(statusKey){
      if (statusKey === 'new') { return 'info'; }
      if (statusKey === 'paid') { return 'warning'; }
      if (statusKey === 'processing') { return 'warning'; }
      if (statusKey === 'in_delivery') { return 'warning'; }
      if (statusKey === 'ready_for_pickup') { return 'warning'; }
      if (statusKey === 'done') { return 'success'; }
      if (statusKey === 'failed') { return 'danger'; }
      if (statusKey === 'refunded') { return 'danger'; }
      if (statusKey === 'refund_failed') { return 'danger'; }
    },
  },
  created() {

  }
};
</script>

<style scoped lang="scss">
.font-15{
  font-size: 15px;
}
.font-13{
  font-size: 13px;
}
.files-container {
  padding-left: 1.5rem;
  > li { padding-top: 0.5rem; }
}
</style>
