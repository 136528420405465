<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">Отзывы</div>

            <div class="row mt-3">
              <div class="col-lg-12">
                <table class="table table-hover" style="cursor: pointer;">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Оценка</th>
                    <th>Отзыв</th>
                    <th>Заказ</th>
                    <th>Создан</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in reviews" :key="item.id">
                    <td>{{item.id}}</td>
                    <td>{{item.rating}}</td>
                    <td>{{item.review}}</td>
                    <td>{{item.order_id}}</td>
                    <td>{{item.created_at}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";

const copyArrObj = new CopyArrayAndObject();

/**
 * Reviews component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      reviews: [],
      searchString: '',
      clickedNotes: "",
      submitted: false,
      title: this.$t('menuitems.management.list.reviews'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.reviews'),
          active: true
        }
      ],
    };
  },
  validations: {

  },
  methods: {
    getPrinters(){
      axios
          .post(`/v1/reviews/get-all`)
          .then(resp => {
            this.reviews = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
  },
  created() {
    this.getPrinters();
  }
};
</script>

<style scoped lang="scss">
  .search{
    margin-right: 15px;
  }
</style>
