export const menuItems = [
    {
        id: 1,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        perm: 'show-dashboard',
        link: '/'
    },
    {
        id: 2,
        label: 'menuitems.management.text',
        icon: 'ri-settings-2-fill',
        perm: 'show-management',
        subItems: [
            {
                id: 3,
                label: 'menuitems.management.list.franchise',
                link: '/management/franchise',
                perm: 'show-management-franchise',
            },
            {
                id: 4,
                label: 'menuitems.management.list.users',
                link: '/management/users',
                perm: 'show-management-users',
            },
            {
                id: 5,
                label: 'menuitems.management.list.rbac',
                link: '/management/rbac',
                perm: 'show-management-rbac',
            },
            {
                id: 6,
                label: 'menuitems.management.list.printers',
                link: '/management/printers',
                perm: 'show-management-printers',
            },
            {
                id: 7,
                label: 'menuitems.management.list.printers_sis',
                link: '/management/printers-sis',
                perm: 'printers_sis_su',
            },
            {
                id: 8,
                label: 'menuitems.management.list.printers_map',
                link: '/management/printers-map',
                perm: 'show-management-printers-map',
            },
            {
                id: 9,
                label: 'menuitems.management.list.orders',
                link: '/management/orders',
                perm: 'show-orders',
            },
            {
                id: 10,
                label: 'menuitems.management.list.clients',
                link: '/management/clients',
                perm: 'show-management-clients',
            },
            {
                id: 11,
                label: 'menuitems.management.list.banners',
                link: '/management/banners',
                perm: 'crud-banners',
            },
            {
                id: 14,
                label: 'menuitems.management.list.localization',
                link: '/management/localization',
                perm: 'show-management-localization',
            },
            {
                id: 17,
                label: 'menuitems.management.list.metro_stations',
                link: '/management/metro-stations',
                perm: 'show-management-metrostations',
            },
            {
                id: 18,
                label: 'menuitems.management.list.promo_codes',
                link: '/management/promo-codes',
                perm: 'show-management-promocodes',
            },
            {
                id: 19,
                label: 'menuitems.management.list.push_notifications',
                link: '/management/push-notifications',
                perm: 'show-management-pushnot',
            },
            {
                id: 20,
                label: 'menuitems.management.list.reviews',
                link: '/management/reviews',
                perm: 'show-management-users',
            },
        ]
    },
    {
        id: 12,
        label: 'menuitems.admin.text',
        icon: 'ri-server-line',
        perm: 'show-admin',
        subItems: [
            {
                id: 13,
                label: 'menuitems.admin.list.servers',
                link: '/admin/servers',
                perm: 'show-admin-servers',
            },
            {
                id: 14,
                label: 'menuitems.admin.list.configs',
                link: '/admin/configs',
                perm: 'show-admin-configs',
            }
        ]
    },
    {
        id: 15,
        label: 'menuitems.services.text',
        icon: 'dripicons-network-2',
        perm: 'show-services',
        subItems: [
            {
                id: 16,
                label: 'menuitems.services.list.points',
                link: '/services/points',
                perm: 'show-services-points',
            },
            {
                id: 20,
                label: 'menuitems.services.list.templates',
                link: '/services/templates',
                perm: 'show-services-templates',
            },
        ]
    }
]
